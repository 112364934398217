import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import jwt_decode from 'jwt-decode';

const GoogleVerification = () => {
  const { accessToken, idToken, refreshToken } = queryString.parse(
    window.location.search,
  );
  const navigate = useNavigate();

  useEffect(() => {
    const decodedToken = jwt_decode(idToken);
    localStorage.setItem('idToken', idToken);
    localStorage.setItem('userId', decodedToken.id);
    localStorage.setItem('userRole', decodedToken.role);
  }, [accessToken, idToken, refreshToken]);

  useEffect(() => {
    navigate('/admin/dashboard/backlinks', {
      replace: true,
    });
  }, [idToken, navigate]);
};

export default GoogleVerification;
