import { get, getUserId } from 'api';
import { createContext, useState, useEffect } from 'react';

export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const [allProjects, setAllProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState({});

  const getAllProjects = async () => {
    const userId = getUserId();
    if (!userId) return;
    await get(`/api/projects/all`)
      .then((res) => res.json())
      .then((data) => {
        if (data.data.length > 0) {
          setAllProjects(data.data);
        } else {
          setAllProjects([]);
        }
      });
  };

  const getProjectInfo = async (id) => {
    const userId = getUserId();
    if (!userId) return;
    await get(`/api/projects/user-project/${userId}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.data.length > 0) {
          if (id) {
            const project = data.data.filter((item) => item.id === id);
            setCurrentProject(project[0]);
            localStorage.setItem('currentProject', JSON.stringify(project[0]));
            return;
          }
          setCurrentProject(data.data[0]);
          localStorage.setItem('currentProject', JSON.stringify(data.data[0]));
        } else {
          setCurrentProject({});
          localStorage.setItem('currentProject', JSON.stringify({}));
        }
      });
  };

  useEffect(() => {
    getAllProjects();
    const storedProjects = localStorage.getItem('currentProject');
    if (
      storedProjects &&
      Object.values(JSON.parse(storedProjects)).length > 0
    ) {
      setCurrentProject(JSON.parse(storedProjects));
    } else {
      getProjectInfo();
    }
  }, []);

  const changeProject = (item) => {
    setCurrentProject(item);
    localStorage.setItem('currentProject', JSON.stringify(item));
  };

  return (
    <ProjectContext.Provider
      value={{
        allProjects,
        currentProject,
        changeProject,
        getProjectInfo,
        getAllProjects,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
