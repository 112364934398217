import React, { useMemo, useState, useEffect, useContext } from 'react';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Select from 'react-select';
import Card from 'components/card';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { get, getUserRole } from 'api';
import { FiSearch } from 'react-icons/fi';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { IoMdFunnel } from 'react-icons/io';
import TooltipHorizon from 'components/tooltip';
import { ProjectContext } from 'contexts/ProjectContext';
import InputField from 'components/fields/InputField';

const ReportsTable = (props) => {
  const { columnsData } = props;
  const { currentProject } = useContext(ProjectContext);
  const [reports, setReports] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().subtract(30, 'days').format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const isAdmin = getUserRole() === 'admin' ? true : false;

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const handleExport = async () => {
    setSubmitting(true);
    const fileName = currentProject.projectName + ' - Reports';
    let excelData = reports.filter((report) => {
      const reportDate = moment(report.updatedAt).format('YYYY-MM-DD');
      return (
        moment(reportDate).isSameOrAfter(startDate) &&
        moment(reportDate).isSameOrBefore(endDate)
      );
    });

    excelData = excelData.map((report) => ({
      'Project Name': currentProject.projectName,
      'Domain Name': report.domainName,
      'Domain Rating': report.domainRating,
      'Organic Traffic': report.organicTraffic,
      'Ahrefs Ranking': report.ahrefsRanking,
      'Article URL': report.articleUrl,
      'Article Anchor': report.articleAnchor,
      Price: `€ ${report.price}`,
      'Updated At': moment(report.updatedAt).format('YYYY-MM-DD'),
    }));

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { report: ws }, SheetNames: ['report'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    setSubmitting(false);
  };

  const projectId = isAdmin ? selectedProject?.value : currentProject.id;

  const getReports = async () => {
    await get(`/api/reports/project/${projectId}`).then((res) =>
      res.json().then((data) => {
        if (!data.data) {
          setReports([]);
          setLoaded(true);
          return;
        } else {
          setReports(data.data);
          setLoaded(true);
        }
      }),
    );
  };

  const getAllReports = async () => {
    await get(`/api/reports/all`).then((res) =>
      res.json().then((data) => {
        if (!data.data) {
          setReports([]);
          setLoaded(true);
          return;
        } else {
          setReports(data.data);
          setLoaded(true);
        }
      }),
    );
  };

  const getAllProjects = async () => {
    await get('/api/projects/all').then((res) =>
      res.json().then((data) => {
        const newData = data.data.map((item) => {
          return {
            value: item.id,
            label: item.projectName,
          };
        });
        setProjects(newData);
        setLoaded(true);
      }),
    );
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  useEffect(() => {
    if (currentProject) {
      getReports();
    }
  }, [currentProject]);

  useEffect(() => {
    if (selectedProject && selectedProject.value !== 'all') {
      getReports();
    }
  }, [selectedProject]);

  const handleDataChange = (e) => {
    if (e.target.id === 'startDate') {
      setStartDate(e.target.value);
    } else {
      setEndDate(e.target.value);
    }
  };

  const handleProjectChange = async (selectedOption) => {
    if (selectedOption.value === 'all') {
      setSelectedProject(selectedOption);
      getAllReports();
    } else {
      setSelectedProject(selectedOption);
    }
  };

  const filteredReports = useMemo(() => {
    return reports.filter((report) => {
      const reportDate = moment(report.updatedAt).format('YYYY-MM-DD');
      return (
        moment(reportDate).isSameOrAfter(startDate) &&
        moment(reportDate).isSameOrBefore(endDate)
      );
    });
  }, [reports, startDate, endDate]);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => filteredReports, [filteredReports]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setGlobalFilter,
    setPageSize,
    state,
  } = tableInstance;

  const { pageSize } = state;

  const options = [{ value: 'all', label: 'Select All' }, ...projects];

  if (!loaded) {
    return <div>Loading...</div>;
  }

  return (
    <Card extra={'w-full sm:overflow-auto p-4'}>
      {isAdmin && (
        <div className="mb-2 flex w-full items-center justify-between md:w-1/5">
          <Select
            options={options}
            value={selectedProject}
            onChange={handleProjectChange}
            name="project"
            menuPlacement="bottom"
            menuPortalTarget={document.body}
            placeholder="Select project to view reports"
            className="w-full"
          />
        </div>
      )}
      <div className="mb-4 flex flex-col items-center gap-4 md:flex-row">
        <div className="mt-2 flex w-full md:w-1/5">
          <InputField
            label="Start Date"
            value={moment(startDate).format('YYYY-MM-DD')}
            type="date"
            id="startDate"
            onChange={handleDataChange}
            extra="mr-2 w-full"
            max={moment().format('YYYY-MM-DD')}
          />
          <InputField
            label="End Date"
            value={moment(endDate).format('YYYY-MM-DD')}
            type="date"
            id="endDate"
            extra="w-full"
            onChange={handleDataChange}
            max={moment().format('YYYY-MM-DD')}
          />
        </div>
        <div className="flex h-full w-full items-end gap-2 md:w-auto">
          <button
            disabled={submitting}
            onClick={() => handleExport()}
            className="linear flex h-[48px] w-auto items-center justify-center rounded-full bg-brand-500 p-4 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Export to CSV
          </button>
        </div>
      </div>

      {/* Search */}
      <div
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="flex w-full items-center rounded-[10px] bg-white shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mt-2 md:w-1/5"
      >
        <div className="flex h-9 w-full flex-grow items-center rounded-[10px] bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
          <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
          <input
            type="text"
            placeholder="Search...."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
          />
        </div>
      </div>
      <div className="mt-8 h-full max-w-[100%] overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse whitespace-nowrap"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pb-[10px] pr-16 dark:!border-navy-700"
                    key={index}
                  >
                    <div className="text-start text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      <div className="flex items-center gap-2">
                        {column.render('Header')}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <BsChevronUp className="mr-1 inline h-4 w-4" />
                          ) : (
                            <BsChevronDown className="mr-1 inline h-4 w-4" />
                          )
                        ) : (
                          <IoMdFunnel className="mr-1 inline h-4 w-4" />
                        )}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className="h-[60px] items-center border-b border-gray-200 dark:!border-white/10"
                >
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'ID') {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            #{cell.value}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === 'DOMAIN NAME') {
                      const articleURL = cell.row.original.articleURL;
                      data = (
                        <TooltipHorizon
                          key={index}
                          extra="max-h-[200px] overflow-y-auto"
                          trigger={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              <a
                                href={articleURL}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {cell.value && cell.value.slice(0, 18)}
                                {cell.value && cell.value.length > 18 && (
                                  <span>...</span>
                                )}
                              </a>
                            </p>
                          }
                          content={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {articleURL}
                            </p>
                          }
                          placement="top"
                        />
                      );
                    } else if (cell.column.Header === 'ARTICLE ANCHOR') {
                      data = (
                        <TooltipHorizon
                          key={index}
                          extra="max-h-[200px] overflow-y-auto"
                          trigger={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value && cell.value.slice(0, 18)}
                              {cell.value && cell.value.length > 18 && (
                                <span>...</span>
                              )}
                            </p>
                          }
                          content={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          }
                          placement="top"
                        />
                      );
                    } else if (cell.column.Header === 'DR') {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === 'OT') {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value && cell.value.toLocaleString()}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === 'AR') {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value && cell.value.toLocaleString()}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === 'ARTICLE URL') {
                      data = (
                        <TooltipHorizon
                          key={index}
                          extra="max-h-[200px] overflow-y-auto"
                          trigger={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              <a
                                href={cell.value}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {cell.value && cell.value.slice(0, 18)}
                                {cell.value && cell.value.length > 18 && (
                                  <span>...</span>
                                )}
                              </a>
                            </p>
                          }
                          content={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          }
                          placement="top"
                        />
                      );
                    } else if (cell.column.Header === 'PRICE') {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            € {cell.value && cell.value.toLocaleString()}
                          </div>
                        </div>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pb-[16px] pt-[14px] sm:text-[14px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="mt-10 flex h-[80px] w-full items-center justify-between md:px-6">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name=""
            id=""
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="> Show rows per page text-sm text-gray-700">
            Showing {pageSize * state.pageIndex + 1}-
            {pageSize * state.pageIndex + pageSize} of {reports.length}
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            {state.pageIndex + 1} of {Math.ceil(reports.length / pageSize)}
          </p>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default ReportsTable;
