import { useState, useContext, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import Domain from './components/Domain';
import Card from 'components/card';
import { put, getUserId } from 'api';
import { ProjectContext } from 'contexts/ProjectContext';

const EditProject = () => {
  const { currentProject, getProjectInfo } = useContext(ProjectContext);
  const [projectInfo, setProjectInfo] = useState({});

  useEffect(() => {
    if (currentProject) {
      setProjectInfo({
        projectName: currentProject.projectName,
        domainName: currentProject.domainName,
        description: currentProject.description,
      });
    } else {
      getProjectInfo();
    }
  }, [currentProject]);

  const updateProjectInfo = (info) => {
    setProjectInfo((prevInfo) => ({ ...prevInfo, ...info }));
  };

  const handleSubmit = async () => {
    const userId = getUserId();
    const domainName = projectInfo.domainName;
    const description = projectInfo.description;
    const projectName = projectInfo.projectName;
    if (!projectName || !domainName || !description) {
      toastError('Please fill all the fields');
      return;
    }
    const body = {
      userId,
      projectName,
      domainName,
      description,
    };
    await put(`/api/projects/update-project/${currentProject.id}`, body).then(
      (res) =>
        res.json().then((parsedData) => {
          if (parsedData.status === 'SUCCESS') {
            toastSuccess('Project updated successfully');
            getProjectInfo(currentProject.id);
          } else {
            toastError('Project update failed');
          }
        }),
    );
  };

  const handleDelete = async () => {
    const userId = getUserId();
    if (
      window.confirm(
        'Are you sure you want to delete this project, as you will lose all the information related to this project?',
      )
    ) {
      const body = {
        userId: userId,
      };
      await put(`/api/projects/archive/${currentProject.id}`, body).then(
        (res) => {
          if (res.status === 200) {
            toastSuccess('Project deleted successfully');
            getProjectInfo();
            setTimeout(() => {
              window.location.href = '/admin/dashboards/projects/overview';
            }, 2500);
          } else {
            toastError('Project deletion failed');
          }
        },
      );
    }
  };

  return (
    <div className="mt-3 h-full w-full">
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-brand-400 to-brand-600 md:h-[390px]" />
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
        <div className="m-auto -mt-[280px] w-[50%] pb-10 md:-mt-[240px] md:px-[0px]" />
        <Card extra={'h-full mx-auto pb-3'}>
          <div className="rounded-[20px]">
            <Domain
              projectInfo={projectInfo}
              updateProjectInfo={updateProjectInfo}
            />
          </div>
          <div className="mt-4 flex justify-center">
            <button
              onClick={() => handleSubmit()}
              className="linear cursor-pointer rounded-xl bg-brand-900 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
            >
              <span>Submit</span>
            </button>
          </div>
          {/* <div className="mt-4 flex flex-col justify-end px-3 pt-2 md:flex-row md:px-8">
            <button
              onClick={() => handleDelete()}
              className="linear cursor-pointer rounded-xl bg-red-500 px-8 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
            >
              Delete
            </button>
          </div> */}
        </Card>
      </div>
    </div>
  );
};

export default EditProject;
