export const reportsHeaders = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'DOMAIN NAME',
    accessor: 'domainName',
  },
  {
    Header: 'DR',
    accessor: 'domainRating',
  },
  {
    Header: 'OT',
    accessor: 'organicTraffic',
  },
  {
    Header: 'AR',
    accessor: 'ahrefsRanking',
  },
  {
    Header: 'ARTICLE URL',
    accessor: 'articleUrl',
  },
  {
    Header: 'ARTICLE ANCHOR',
    accessor: 'articleAnchor',
  },
  {
    Header: 'PRICE',
    accessor: 'price',
  },
];
