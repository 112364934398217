/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiX } from 'react-icons/hi';
import Links from './components/Links';
import { ProjectContext } from 'contexts/ProjectContext';
import SidebarCard from 'components/sidebar/components/SidebarCard';
import {
  renderThumb,
  renderTrack,
  renderView,
  renderViewMini,
} from 'components/scrollbar/Scrollbar';
import { Scrollbars } from 'react-custom-scrollbars-2';
import routes from 'routes';
import Card from 'components/card';
import { Link } from 'react-router-dom';
import logoDark from 'assets/img/logo/logoDark.png';
import icon from 'assets/img/logo/Icon.png';
import { get, getUserId } from 'api';

function SidebarHorizon(props) {
  const navigate = useNavigate();
  const { currentProject, changeProject } = useContext(ProjectContext);
  const { open, onClose, variant, mini, hovered, setHovered } = props;
  const [projects, setProjects] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const userId = getUserId();

  useEffect(() => {
    const fetchData = async () => {
      await get(`/api/projects/user-project/${userId}`)
        .then((res) => res.json())
        .then((data) => {
          setProjects(data.data);
        });
      setLoaded(true);
    };
    fetchData();
  }, [userId]);

  const selectOptions = (
    <>
      <option value="">Select a project</option>
      {projects.map((item) => (
        <option key={item.id} value={item.id}>
          {item.projectName}
        </option>
      ))}
      <option value="0">+ Add Project</option>
    </>
  );

  const handleProjectChange = (e) => {
    if (e.target.value === '0') {
      navigate('/admin/dashboards/projects/newProject');
    } else if (e.target.value !== '') {
      const selectedProject = projects.find(
        (project) => project.id === parseInt(e.target.value),
      );
      changeProject(selectedProject);
    }
  };

  if (!loaded) {
    return null;
  }

  return (
    <div
      className={`sm:none ${
        mini === false
          ? 'w-[245px]'
          : mini === true && hovered === true
          ? 'w-[245px]'
          : 'w-[245px] xl:!w-[120px]'
      } duration-175 linear fixed !z-50 min-h-full transition-all md:!z-50 lg:!z-50 xl:!z-0 ${
        variant === 'auth' ? 'xl:hidden' : 'xl:block'
      } ${open ? '' : '-translate-x-[105%]'}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Card
        extra={`ml-3 w-full h-[96.5vh] sm:mr-4 sm:my-4 m-7 !rounded-[20px]`}
      >
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={
            mini === false
              ? renderView
              : mini === true && hovered === true
              ? renderView
              : renderViewMini
          }
        >
          <div className="flex h-full flex-col justify-between">
            <div>
              <span
                className="absolute right-4 top-4 block cursor-pointer xl:hidden"
                onClick={onClose}
              >
                <HiX />
              </span>
              <div
                className={`ml-[12px] mr-[12px] mt-[16px] flex items-center justify-center `}
              >
                <div
                  className={`font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white ${
                    mini === false
                      ? 'block'
                      : mini === true && hovered === true
                      ? 'block'
                      : 'hidden'
                  }`}
                >
                  <Link to="/admin/dashboard/backlinks">
                    <img src={icon} alt="logo" className="h-[90px] w-[90px]" />
                  </Link>
                </div>
                <div
                  className={`font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white ${
                    mini === false
                      ? 'hidden'
                      : mini === true && hovered === true
                      ? 'hidden'
                      : 'block'
                  }`}
                >
                  <img src={icon} alt="icon" />
                </div>
              </div>
              {/* Nav item */}
              <div className="mt-[16px] h-[56px] w-[232px] rounded-[5px] bg-lightPrimary p-1 dark:!border-white/10 dark:!bg-navy-800">
                <h6 className="text-inherit ml-[4px] block font-sans text-base font-semibold capitalize leading-relaxed tracking-normal text-navy-800 antialiased dark:text-white ">
                  Project
                </h6>
                <select
                  className="h-[24px] w-[220px] bg-lightPrimary dark:!border-white/10 dark:!bg-navy-800"
                  value={currentProject.id || ''}
                  onChange={(e) => handleProjectChange(e)}
                >
                  {selectOptions}
                </select>
              </div>
              <div className="mb-7 mt-[8px] h-px bg-gray-200 dark:bg-white/10" />
              <ul>
                <Links mini={mini} hovered={hovered} routes={routes} />
              </ul>
            </div>
          </div>
        </Scrollbars>
      </Card>
    </div>
  );
}

export default SidebarHorizon;
