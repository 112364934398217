import { MdHome, MdLock } from 'react-icons/md';

import NewProject from 'views/admin/dashboards/projects/newProject';
import EditProject from 'views/admin/dashboards/projects/editProject';
import Backlinks from 'views/admin/dashboards/backlinks';
import AdminBacklinks from 'views/admin/dashboards/admin';
import GoogleVerification from 'views/auth/google';
import SignIn from 'views/auth/signIn/SignIn';
import Reports from 'views/admin/dashboards/reports';

const routes = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: <MdHome className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: 'Link Monitoring',
        layout: '/admin',
        path: '/dashboard/backlinks',
        exact: false,
        component: <Backlinks />,
      },
      {
        name: 'Admin Links',
        layout: '/admin',
        path: '/dashboard/admin',
        component: <AdminBacklinks />,
        exact: false,
        adminView: true,
      },
      {
        name: 'New Project',
        layout: '/admin',
        path: '/dashboards/projects/newProject',
        exact: false,
        component: <NewProject />,
      },
      {
        name: 'Edit Project',
        layout: '/admin',
        path: '/dashboards/projects/editProject',
        exact: false,
        component: <EditProject />,
      },
      {
        name: 'Reports',
        layout: '/admin',
        path: '/dashboards/reports',
        exact: false,
        component: <Reports />,
      },
    ],
  },
  // --- Authentication ---
  {
    name: 'Authentication',
    hide: true,
    path: '/',
    icon: <MdLock className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: 'Log In',
        layout: '/auth',
        path: '/',
        component: <SignIn />,
      },
      {
        name: 'Google Log In',
        layout: '/auth',
        path: '/google',
        component: <GoogleVerification />,
      },
    ],
  },
];
export default routes;
