import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import Domain from './components/Domain';
import Card from 'components/card';
import { post, getUserId } from 'api';

const NewProject = () => {
  const [projectInfo, setProjectInfo] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const updateProjectInfo = (info) => {
    setProjectInfo((prevInfo) => ({ ...prevInfo, ...info }));
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const userId = getUserId();
    const { domainName, description, projectName } = projectInfo;
    if (!projectName || !domainName || !description) {
      toastError('Please fill all the fields');
      setSubmitting(false);
      return;
    }
    const data = {
      userId,
      projectName,
      domainName,
      description,
    };
    await post('/api/projects/new-project', data).then((res) =>
      res.json().then((parsedData) => {
        if (parsedData.status === 'SUCCESS') {
          toastSuccess('Project created successfully');
          const projectId = parsedData.projectId;
          const project = { ...data, id: projectId };
          localStorage.setItem('currentProject', JSON.stringify(project));
          setTimeout(() => {
            setSubmitting(false);
            window.location.href = '/admin/dashboard/backlinks';
          }, 1500);
        } else {
          toastError('Project creation failed');
        }
      }),
    );
  };

  return (
    <div className="mt-3 h-full w-full">
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-brand-400 to-brand-600 md:h-[390px]" />
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
        <div className="m-auto -mt-[280px] w-[50%] pb-10 md:-mt-[240px] md:px-[0px]" />
        <Card extra={'h-full mx-auto pb-3'}>
          <div className="rounded-[20px]">
            <Domain
              projectInfo={projectInfo}
              updateProjectInfo={updateProjectInfo}
            />
          </div>
          <div className="mt-4 flex justify-center">
            <button
              disabled={submitting}
              onClick={() => handleSubmit()}
              className="linear cursor-pointer rounded-xl bg-brand-900 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
            >
              {submitting ? (
                <div className="flex items-center justify-center">
                  <div className="mr-2 h-4 w-4 animate-spin rounded-full border-b-2 border-white" />
                  <span>Submitting..</span>
                </div>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default NewProject;
