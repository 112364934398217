import TextField from 'components/fields/TextField';
import InputField from 'components/fields/InputField';

const Domain = ({ projectInfo, updateProjectInfo }) => {
  const { projectName, domainName, description } = projectInfo;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateProjectInfo({ [name]: value });
  };

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Project information
      </h4>
      {/* inputs */}
      <div className="mt-4">
        <InputField
          label="Project name"
          placeholder="Project"
          id="projectName"
          name="projectName"
          onChange={handleInputChange}
          value={projectName || ''}
          required
        />
      </div>
      <div className="mt-4">
        <InputField
          label={'Domain name'}
          placeholder="example.com"
          id="domainName"
          name="domainName"
          onChange={handleInputChange}
          value={domainName || 'https://'}
          required
        />
      </div>
      <div className="mt-2">
        <TextField
          label="Description"
          placeholder="What is your expectation?"
          id="description"
          cols="30"
          rows="8"
          onChange={handleInputChange}
          value={description || ''}
          required
        />
      </div>
    </div>
  );
};

export default Domain;
