import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from 'utils/scrollTop';
import App from './App';
import { ProjectProvider } from 'contexts/ProjectContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <ProjectProvider>
      <ScrollToTop />
      <App />
    </ProjectProvider>
  </BrowserRouter>,
);
