import React from 'react';
import { ToastContainer } from 'react-toastify';
import { FcGoogle } from 'react-icons/fc';
import Card from 'components/card';
import Centered from 'layouts/auth/types/Centered';

function SignInCenter() {
  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/login/google`;
  };

  return (
    <Centered
      bgImage="linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"
      maincard={
        <Card extra="max-w-[405px] md:max-w-[510px] md:w-[510px] h-max mx-2.5 md:mx-auto mt-[50px] mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
          <ToastContainer
            position="top-center"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <h3 className="mb-[10px] text-4xl font-bold text-gray-900 dark:text-white">
            Sign In
          </h3>
          <div
            onClick={handleLogin}
            className="mt-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary transition duration-200 hover:cursor-pointer hover:bg-brand-500 hover:text-white dark:bg-navy-700 dark:text-white dark:hover:bg-navy-600"
          >
            <div className="rounded-full text-xl">
              <FcGoogle />
            </div>
            <p className="text-sm font-medium dark:text-white">
              Sign In with Google
            </p>
          </div>
        </Card>
      }
    />
  );
}

export default SignInCenter;
